<div class="modal-header">
  <h6 class="modal-title">
    <span class="icon-left">
      <i class="feather icon-map-pin" aria-hidden="true"></i>
    </span>
    Credit Limit Request Form
  </h6>
  <p class="btn-close" aria-label="Close" (click)="close()" aria-hidden="true" class="cursor-pointer">
    ×
  </p>
</div>
<div class="modal-body">
  <form [formGroup]="CreditLimitReqForm" (ngSubmit)="onSubmit()">
    <div class="collapse-icon p-0">
      <div class="collapse-default">
        <div class="form-group mb-1">
          <div class="custom-control custom-switch custom-switch-success">
            <label for="salePerson">Is this Existing Customer?</label>&nbsp;
            <input type="checkbox" class="custom-control-input" id="customSwitch111" formControlName="isNewCustomer"
              (change)="toggleCustomerTypeCheckbox($event)" />
            <label class="custom-control-label" for="customSwitch111">
              <span class="switch-icon-left"><i data-feather="check"></i></span>
              <span class="switch-icon-right"><i data-feather="x"></i></span>
            </label>
          </div>
        </div>
        <div class="form-group mb-1">
          <label for="salePerson">Sales Person</label>
          <ng-select id="salePerson" formControlName="salePersonId" [items]="salePerson" bindLabel="userDisplayName"
            bindValue="userId" placeholder="Primary, Billing, Shipper, Consignee, etc" [ngClass]="{
            'ng-touched': CreditLimitReqForm.get('salePersonId').touched,
            error:
            CreditLimitReqForm.get('salePersonId').invalid &&
            CreditLimitReqForm.get('salePersonId').touched
        }" (change)="onsalePersonChange($event)"></ng-select>
          <!-- Hidden field to store the value -->
          <input type="hidden" formControlName="salePersonName" />
          <span *ngIf="
          CreditLimitReqForm.get('salePersonId').invalid &&
          CreditLimitReqForm.get('salePersonId').touched
        " class="invalid-form">
            <small class="form-text text-danger">Please Select a Customer</small>
          </span>
        </div>

        <!-- Show this section if checkbox is checked -->
        <div *ngIf="isExistingCustomer" class="form-group mb-1">
          <label for="customerId">Existing Customer List</label>
          <ng-select #customerId id="customerId" formControlName="customerId" [items]="companies"
            bindLabel="companyName" bindValue="companyId" placeholder="Select Company Name" (change)="onCompanyNameChange($event)"
            [ngClass]="{
              'ng-touched': CreditLimitReqForm.get('customerName').touched,
              error:
              CreditLimitReqForm.get('customerName').invalid &&
              CreditLimitReqForm.get('customerName').touched
            }"
    ></ng-select>
          <input type="hidden" formControlName="customerName" />
          <span *ngIf="
        CreditLimitReqForm.get('customerId').invalid &&
        CreditLimitReqForm.get('customerId').touched
      " class="invalid-form">
            <small class="form-text text-danger">Please Select a Customer</small>
          </span>
          <span *ngIf="CreditLimitReqForm.get('customerName').hasError('required') && CreditLimitReqForm.get('customerName').touched" class="invalid-form">
            <small class="form-text text-danger">Customer Name is required.</small>
          </span>
        </div>

        <!-- Show this section if checkbox is not checked -->
        <div *ngIf="!isExistingCustomer" class="form-group mb-1">
          <input type="hidden" formControlName="customerId" />
          <label for="customerName">Company</label>
          <input id="customerName" formControlName="customerName" type="text" class="form-control"
            placeholder="Company Name" [ngClass]="{
        'ng-touched': CreditLimitReqForm.get('customerName').touched,
        error:
        CreditLimitReqForm.get('customerName').invalid &&
        CreditLimitReqForm.get('customerName').touched
      }" />
          <span *ngIf="
        CreditLimitReqForm.get('customerName').invalid &&
        CreditLimitReqForm.get('customerName').touched
      " class="invalid-form">
            <small class="form-text text-danger">Please Select Company Name</small>
          </span>
        </div>

        <div class="form-group mb-2">
          <label for="country">Select Branch</label>
          <ng-select #branchId id="branch" formControlName="branchId" [items]="branch" bindLabel="companyName"
            bindValue="companyId" placeholder="Select Branch" [ngClass]="{
            'ng-touched': CreditLimitReqForm.get('branchId').touched,
            error:
            CreditLimitReqForm.get('branchId').invalid &&
            CreditLimitReqForm.get('branchId').touched
          }" (change)='onChangeBranch($event)'></ng-select>
          <span *ngIf="
            CreditLimitReqForm.get('branchId').invalid &&
            CreditLimitReqForm.get('branchId').touched
          " class="invalid-form">
            <small class="form-text text-danger">Please Select a Branch</small>
          </span>
          <input formControlName="branchName" type="hidden" />

          <div class="row mb-1 form-group mt-1">
            <div class="col-6">
              <label for="creditLimit">Requested Limit</label>
              <input #creditLimit id="creditLimit" formControlName="creditLimit" type="text" class="form-control"
                placeholder="Credit Limit" (input)="formatCreditLimit($event, CreditLimitReqForm.get('creditLimit'))"
                [ngClass]="{
            'ng-touched': CreditLimitReqForm.get('creditLimit').touched,
            error:
            CreditLimitReqForm.get('creditLimit').invalid &&
            CreditLimitReqForm.get('creditLimit').touched
          }" />
              <span *ngIf="
            CreditLimitReqForm.get('creditLimit').invalid &&
            CreditLimitReqForm.get('creditLimit').touched
          " class="invalid-form">
                <small class="form-text text-danger">Please Select a Branch</small>
              </span>

            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="state">Credit Terms</label>
                <ng-select id="state" formControlName="creditTermId" [items]="creditTerm" bindLabel="paymentTerm1"
                  bindValue="paymentTermId" placeholder="Immediate, 30 Days, etc" [ngClass]="{
                    'ng-touched': CreditLimitReqForm.get('creditTermId').touched,
                    error:
                    CreditLimitReqForm.get('creditTermId').invalid &&
                    CreditLimitReqForm.get('creditTermId').touched
                }" (change)="onChangecreditTerm($event)"></ng-select>
                <span *ngIf="
                    CreditLimitReqForm.get('creditTermId').invalid &&
                    CreditLimitReqForm.get('creditTermId').touched
                " class="invalid-form">
                  <small class="form-text text-danger">Please Select Credit Term</small>
                </span>
                <input formControlName="creditTermName" type="hidden" />

              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label for="note">Note</label>
                <input #note id="note" formControlName="note" type="text" placeholder="Enter Note"
                  class="form-control"       [ngClass]="{'is-invalid': CreditLimitReqForm.get('note').invalid && CreditLimitReqForm.get('note').touched}" 
                  />
                  <span *ngIf="CreditLimitReqForm.get('note').hasError('required') && CreditLimitReqForm.get('note').touched" class="invalid-form">
                    <small class="form-text text-danger">Note is required.</small>
                  </span>
              </div>
              
              <div class="row mb-1 form-group mt-1">
                <div class="col-6">
                  <label for="expectedTurnover">Expected Turnover</label>
                  <input id="expectedTurnover" formControlName="expectedTurnover" type="text" class="form-control"
                    placeholder="Enter Expected Turnover" (input)="onTurnoverInput($event)"
                    [value]="formatNumber(expectedTurnover?.value)" 
                    [ngClass]="{'is-invalid': CreditLimitReqForm.get('expectedTurnover').invalid && CreditLimitReqForm.get('expectedTurnover').touched}" />
                    <span *ngIf="CreditLimitReqForm.get('expectedTurnover').hasError('required') && CreditLimitReqForm.get('expectedTurnover').touched" class="invalid-form">
                      <small class="form-text text-danger">Expected Turnover is required.</small>
                    </span>
                </div>
                <div class="col-6">
                  <label for="expectedProfit">Expected Profit</label>
                  <input id="expectedProfit" formControlName="expectedProfit" type="text" class="form-control"
                    placeholder="Enter Expected Profit" (input)="onProfitInput($event)"
                    [value]="formatNumber(expectedProfit?.value)"
                    [ngClass]="{'is-invalid': CreditLimitReqForm.get('expectedProfit').invalid && CreditLimitReqForm.get('expectedProfit').touched}"  />
                    <span *ngIf="CreditLimitReqForm.get('expectedProfit').hasError('required') && CreditLimitReqForm.get('expectedProfit').touched" class="invalid-form">
                      <small class="form-text text-danger">Expected Profit is required.</small>
                    </span>
                </div>
              </div>

            </div>

            <div class="col-12">
              <div class="form-group">
                <label for="profitPercentage">Profit Percentage</label>
                <input id="profitPercentage" formControlName="profitPercentage" type="number" class="form-control"
                  placeholder="Profit Percentage" readonly
                  />
              </div>
            </div>
          </div>
        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" (click)="close()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success" [disabled]="isSubmitting">  {{ isSubmitting ? 'Processing...' : 'Send for Approval' }}
          </button>
        </div>
      </div>
    </div>
  </form>