<div class="content-body">
  <div class="f-listmargin">
    <section class="customer-list-wrapper">
      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <div class="d-flex align-items-center">
                <h1 class="mr-2 mb-0">Credit Limit Requests</h1>
                <span class="badge badge-warning" *ngIf="awaitingApprovedCount">{{awaitingApprovedCount}} Pending</span>
              </div>

            </div>

          </div>
          <div class="
                col-md-6 col-lg-4 col-12
                d-flex
                justify-content-start justify-content-md-end
                align-items-center
                offset-lg-2
              ">
            <div class="ml-5">&nbsp;&nbsp;&nbsp;</div>
            <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
              <div
                class="col-10 col-md-12 mt-2 ml-1 ml-md-0 mt-md-0 d-flex justify-content-start justify-content-md-end  "
                style="height: 40px;">
                <div>
                  <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control"
                    placeholder="Search by Status..." (keyup)="filterUpdate($event)" (search)="filterUpdate($event)" />
                </div>
                <div class="btn-group" *ngIf="isDelegate === false">

                  <div>
                    <button class="btn btn-primary mr-1 ml-1" type="button" id="dropdownMenuButton" rippleEffect
                      (click)="openCreditLimitReqModal()">
                      New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ngx-datatable class="bootstrap core-bootstrap" [rows]="livRequests" [rowHeight]="getRowHeight"
          [count]="totalRecords" [offset]="pageNumber - 1" [limit]="pageSize" [loadingIndicator]="loading"
          [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true"
          (page)='onPage($event)'>

          <ngx-datatable-column name="Company Name" prop="customerName" [width]="240" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-customerName="value" let-row="row">
              <div class="font-weight-bold wrap-text">
                <a *ngIf="row.status !== 'Revised'" [routerLink]="['/liv-preview', row['livRequestId']]">
                  {{ customerName }}
                </a>
                <span *ngIf="row.status === 'Revised'" [style.cursor]="'pointer'" [style.color]="'#7367F0'"
                  (click)="openCreditLimitReqModal(row)" class="clickable">
                  {{ customerName }}
                </span>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Sales Person" prop="salesPersonID" [width]="150">
            <ng-template let-salesPersonID="value" ngx-datatable-cell-template>
              {{ salesPerson.get(salesPersonID) || 'Unknown' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Branch" prop="branchName" [width]="80">
            <ng-template let-branchName="value" ngx-datatable-cell-template>
              {{ branchName }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Limit" prop="creditLimit" [width]="80">
            <ng-template let-creditLimit="value" ngx-datatable-cell-template>
              {{ creditLimit }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Term" prop="creditTerms" [width]="80">
            <ng-template let-creditTerms="value" ngx-datatable-cell-template>
              {{ creditTerms }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Requested Date" prop="requestedDate" [width]="120">
            <ng-template let-requestedDate="value" ngx-datatable-cell-template>
              {{ requestedDate | date: 'dd / MM / yyyy HH:mm' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Status" prop="status" [width]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span [ngClass]="{
                  'badge-custom': true,
                  'badge-success badge-light-success': row.status === 'Approved',
                  'badge-danger badge-light-danger': row.status === 'Rejected',
                  'badge-warning badge-light-warning': row.status === 'Canceled',
                  'badge-info badge-light-info': row.status === 'Awaiting Approval',
                  'badge-primary badge-light-primary': row.status === 'Revised'
                }">
                {{ row.status }}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Action" [width]="130">
            <ng-template ngx-datatable-cell-template let-row="row">
              <span (click)="loadLivTaskTimeLine(row)"
                style="color: #7367F0; cursor: pointer; font-weight: bold; margin-right: 10px;">
                <i class="fa fa-edit" style="margin-right: 5px;"></i> Edit
              </span>

              <span (click)="deleteLivTask(row)" style="color: #dc3545; cursor: pointer; font-weight: bold;">
                <i class="fa fa-trash" style="margin-right: 5px;"></i> Delete
              </span>
            </ng-template>
          </ngx-datatable-column>


        </ngx-datatable>
        <ul class="pagination justify-content-end pr-1">
          <li class="page-item" [class.disabled]="pageNumber === 1">
            <a class="page-link" (click)="goToPreviousPage()">Previous</a>
          </li>
          <li *ngIf="pages[0] > 1" class="page-item">
            <a class="page-link" (click)="goToPage(1)">1</a>
          </li>
          <li *ngIf="pages[0] > 2" class="page-item disabled">
            <span class="page-link">...</span>
          </li>

          <li *ngFor="let page of pages" class="page-item" [class.active]="page === pageNumber">
            <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
          </li>

          <li *ngIf="pages[pages.length - 1] < totalPages - 1" class="page-item disabled">
            <span class="page-link">...</span>
          </li>
          <li *ngIf="pages[pages.length - 1] < totalPages" class="page-item">
            <a class="page-link" (click)="goToPage(totalPages)">{{ totalPages }}</a>
          </li>

          <li class="page-item" [class.disabled]="pageNumber === totalPages">
            <a class="page-link" (click)="goToNextPage()">Next</a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</div>