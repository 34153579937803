<div class="scroll-container">
    <div class="d-flex mt-1">
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-left">
                    <div class=" pb-0">
                        <!-- <div class="avatar-content">
                            <i data-feather="package" class="avatar-icon" style="width: 50px; height: 50px; stroke-width: 2;"></i>
                        </div> -->
                        <div class="title-container">
                        <h4 class="mt-1 title">
                            <b>{{ totalSales?.title   }}</b>
                        </h4>
                    </div>
                    </div>
                    <div class=" mt-1">
                        <p class="value">{{totalSales?.shipmentCount}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-left">
                    <div class=" pb-0">
                        <!-- <div class="avatar-content-red">
                            <i data-feather="bar-chart-2" class="avatar-icon" style="width: 50px; height: 50px;" ></i>
                        </div> -->
                        <div class="title-container">
                            <h4 class="mt-1 title"><b>{{ realizedRevenue?.title }}</b></h4>
                        </div>                        
                    </div>
                    <div class=" mt-0">
                        <p class="value">INR</p>
                        <p class="value">{{realizedRevenue?.totalRealizedRevenue | number:'1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-left">
                    <div class=" pb-0">
                        <!-- <div class="avatar-content-red">
                            <i data-feather="dollar-sign" class="avatar-icon" style="width: 50px; height: 50px;" ></i>
                        </div> -->
                        <div class="title-container ">
                        <h4 class="mt-1 title">
                                <b>{{ mainTitle }}</b><span class="sub-title">{{ subTitle }}</span>
                        </h4>
                        
                        </div>
                    </div>
                    <div class=" mt-0">
                        <p class="value">INR</p>
                        <p class="value">{{sumOfRealizedRevenueLast60Days?.totalRealizedRevenueLast60Days | number:'1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="scroll-container">
  <div class="card-container d-flex flex-nowrap gap-3">
    <div class="card totalsale rounded-corners shadow-sm gradient-bg-blue">
      <div class="card-body d-flex flex-column align-items-start text-white">
        <div class="badge bg-light text-dark py-1 px-3 rounded-pill mb-2">
          Sales Overview
        </div>
        <h5 class="card-title font-weight-bold">{{ totalSales?.title }}</h5>
        <p class="value h4 mt-auto">{{ totalSales?.shipmentCount }}</p>
      </div>
    </div>

    <div class="card realized-revenue rounded-corners shadow-sm gradient-bg-green">
      <div class="card-body d-flex flex-column align-items-start text-white">
        <h5 class="card-title font-weight-bold">{{ realizedRevenue?.title }}</h5>
        <p class="value h4 mt-auto">INR {{ realizedRevenue?.totalRealizedRevenue | number:'1.2-2' }}</p>
      </div>
    </div>

    <div class="card main-revenue rounded-corners shadow-sm gradient-bg-purple">
      <div class="card-body d-flex flex-column align-items-start text-white">
        <h5 class="card-title font-weight-bold">
          {{ mainTitle }} <span class="sub-title text-light">{{ subTitle }}</span>
        </h5>
        <p class="value h4 mt-auto">INR {{ sumOfRealizedRevenueLast60Days?.totalRealizedRevenueLast60Days | number:'1.2-2' }}</p>
      </div>
    </div>
  </div>
</div> -->
