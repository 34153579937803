<!-- Accordion Basic start -->
<!-- <div class="rounded-corner-accordian">
  <section id="accordion ">
    <div class="row">
      <div class="col-sm-12">
        <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">

          <div class="card-body collapse-icon p-0">

            <div class="collapse-default">
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="panelBorderBottom1">
                <ngb-panel id="panelBorderBottom1">
                  <ng-template ngbPanelTitle>
                    <div class="panel-title">
                      <h5 style="display: flex; align-items: center;">
                        <i class="feather icon-list" style="font-size: 20px; margin-right: 8px;"></i>
                        Additional Details
                      </h5>                      
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row">
                      <div class="col-md-4">Industry</div>
                      <div class="col-md-8"><b>{{ getLeadIndustryName(leadDetails?.industryId) }}</b></div>
                    </div>
                    <div class="row pt-1">
                      <div class="col-md-4">Annual Revenue</div>
                      <div class="col-md-8"><b>{{leadDetails?.annualRevenue}}</b></div>
                    </div>
                    <div class="row pt-1">
                      <div class="col-md-4">Employee Count</div>
                      <div class="col-md-8"><b>{{leadDetails?.employeeCount}}</b></div>
                    </div>
                  </ng-template>
                </ngb-panel>

              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div> -->
<!-- Accordion Basic end -->




<!-- Card Layout Start -->
<div class="rounded-corner-accordian">
  <section id="accordion p-5">
    <div class="row">
      <div class="col-sm-12">
        <div class="row ">
          <div class="col-md-12 mt-1 ml-1">
            <h5 style="display: flex; align-items: center;">
              <i class="feather icon-list" style="font-size: 20px; margin-right: 8px;"></i>
              Additional Details
            </h5>
          </div>
        </div>
          <div class="row pt-1 pl-1">
            <div class="col-md-4">Industry</div>
            <!-- <div class="col-md-8"><b>{{ getLeadIndustryName(leadDetails?.industryId) }}</b></div> -->
            <div class="col-md-8"><b>{{ lead?.industryName }}</b></div>
          </div>
          <div class="row pt-1 pl-1">
            <div class="col-md-4">Annual Revenue</div>
            <div class="col-md-8"><b>{{lead?.annualRevenue}}</b></div>
          </div>
          <div class="row pt-1 pl-1 mb-1">
            <div class="col-md-4">Employee Count</div>
            <div class="col-md-8"><b>{{lead?.employeeCount}}</b></div>
          </div>
        
      </div>
    </div>
  </section>
</div>
<!-- Card Layout End -->