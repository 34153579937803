
        <section class="customer-list-wrapper">
            <div class="">
                <div class="row">
                    <form [formGroup]="leadForm" (ngSubmit)="saveLead(leadForm)">
                        <div class="fixheader">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h1><b>{{ isEditMode ? 'Update Lead' : 'New Lead' }}</b></h1>
                                    </div>
                                </div>
                                <div
                                    class="col-md-6 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2">
                                    <div class="row pr-1 pb-1 pb-md-0 align-items-center ">
                                        <div
                                            class="col-10 col-md-12 mt-2 ml-1 ml-md-0 mt-md-0 d-flex justify-content-start justify-content-md-end">
                                            <button type="button" class="btn btn-outline-danger mr-1"
                                                routerLink="/leadsList" rippleEffect>Cancel</button>
                                            <button class="btn btn-success" type="submit" rippleEffect>{{
                                                isEditMode ? 'Update' : 'Save' }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="f-preview-overview-content-new ">
                            <div class="row">
                                
                                <div class="col-md-6">
                                    <!-- First Card Section -->
                                    <div class="mb-2">
                                        <div class="rounded-corner-card">
                                            <div class="card">
                                                <div class="card-header">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <h5 style="display: flex; align-items: center;">
                                                            <i class="feather icon-list" style="font-size: 20px; margin-right: 8px;"></i>
                                                            Basic Details
                                                          </h5>      
                                                        
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">

                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="salesPerson">Lead
                                                                    Owner (Sales
                                                                    Person)</label>
                                                                <div class="form-group">
                                                                    <ng-select formControlName="salesPerson"
                                                                        [items]="salesPerson" bindValue="userId"
                                                                        bindLabel="userDisplayName"
                                                                        placeholder="Select Sales Person">
                                                                        <ng-option *ngFor="let person of salesPerson"
                                                                            [value]="person.userId">{{
                                                                            person.userDisplayName
                                                                            }}</ng-option>
                                                                    </ng-select>

                                                                    <span
                                                                        *ngIf="leadForm.get('salesPerson').invalid && (leadForm.get('salesPerson').touched || submitted)"
                                                                        class="invalid-form">
                                                                        <small class="form-text text-danger">Please
                                                                            Select
                                                                            Lead
                                                                            Owner</small>
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="companyName">Company
                                                                    Name</label>
                                                                <input type="text" id="companyName"
                                                                    formControlName="companyName" class="form-control"
                                                                    placeholder="Customer Name" maxlength="50" />
                                                                <span
                                                                    *ngIf="leadForm.get('companyName').invalid && leadForm.get('companyName').touched"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Company
                                                                        Name
                                                                        Cannot
                                                                        be
                                                                        blank!</small>
                                                                    <small class="form-text text-danger"
                                                                        *ngIf="leadForm.get('companyName').hasError('maxlength')">Company
                                                                        Name
                                                                        cannot
                                                                        exceed
                                                                        50
                                                                        characters!</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="leadStatus">Lead
                                                                    Status</label>
                                                                <div class="form-group f-autocomplet-wrapper">
                                                                    <ng-select [items]="leadStatuses" bindLabel="leadStatusName"
                                                                        bindValue="leadStatusId" placeholder="Select Lead Status"
                                                                        formControlName="leadStatus"></ng-select>
                                                                    <span
                                                                        *ngIf="leadForm.get('leadStatus').invalid && leadForm.get('leadStatus').touched"
                                                                        class="invalid-form">
                                                                        <small class="form-text text-danger">Lead
                                                                            Status
                                                                            is
                                                                            required</small>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="leadSource">Lead
                                                                    Source</label>
                                                                <div class="form-group f-autocomplet-wrapper">
                                                                    <ng-select [items]="leadSources" bindLabel="marketingSourceName"
                                                                        bindValue="marketingSourceId" placeholder="Select Lead Source"
                                                                        formControlName="leadSource"></ng-select>
                                                                    <span
                                                                        *ngIf="leadForm.get('leadSource').invalid && leadForm.get('leadSource').touched"
                                                                        class="invalid-form">
                                                                        <small class="form-text text-danger">Lead
                                                                            Source
                                                                            is
                                                                            required</small>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="contactName">Contact
                                                                    Name</label>
                                                                <input type="text" id="contactName"
                                                                    formControlName="contactName" class="form-control"
                                                                    placeholder="Contact Name" />
                                                                <span
                                                                    *ngIf="leadForm.get('contactName').invalid && leadForm.get('contactName').touched"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">
                                                                        Contact Name
                                                                        is
                                                                        required</small>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="email">Email</label>
                                                                <input type="text" id="email" formControlName="email"
                                                                    class="form-control" placeholder="Email" />
                                                                <div *ngIf="leadForm.get('email')?.invalid && leadForm.get('email')?.touched"
                                                                    class="invalid-form">
                                                                    <span
                                                                        *ngIf="leadForm.get('email')?.errors?.['required']"
                                                                        class="form-text text-danger">
                                                                        Email is
                                                                        required.
                                                                    </span>
                                                                    <span
                                                                        *ngIf="leadForm.get('email')?.errors?.['email']"
                                                                        class="form-text text-danger">
                                                                        Please
                                                                        enter a
                                                                        valid
                                                                        email
                                                                        address.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="mobile">Mobile</label>
                                                                <input type="text" id="mobile" formControlName="mobile"
                                                                    class="form-control" placeholder="mobile"
                                                                    maxlength="10" />
                                                                <div *ngIf="leadForm.get('mobile')?.invalid && leadForm.get('mobile')?.touched"
                                                                    class="invalid-form">
                                                                    <div *ngIf="leadForm.get('mobile')?.errors?.['required']"
                                                                        class="form-text text-danger">
                                                                        Mobile
                                                                        number
                                                                        is
                                                                        required.
                                                                    </div>
                                                                    <div *ngIf="leadForm.get('mobile')?.errors?.['pattern']"
                                                                        class="form-text text-danger">
                                                                        Please
                                                                        enter a
                                                                        valid
                                                                        mobile
                                                                        number
                                                                        (10
                                                                        digits).
                                                                    </div>
                                                                    <div *ngIf="leadForm.get('mobile')?.errors?.['maxlength']"
                                                                        class="form-text text-danger">
                                                                        Mobile
                                                                        number
                                                                        cannot
                                                                        exceed
                                                                        10
                                                                        digits.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="website">Website</label>
                                                                <input type="text" id="website"
                                                                    formControlName="website" class="form-control"
                                                                    placeholder="Website"
                                                                    [ngClass]="{'is-invalid': leadForm.get('website')?.invalid && leadForm.get('website')?.touched }" 
                                                                    />
                                                                <div *ngIf="leadForm.get('website')?.invalid && leadForm.get('website')?.touched"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="leadForm.get('website')?.errors?.['pattern']">
                                                                        Please
                                                                        enter a
                                                                        valid
                                                                        website
                                                                        URL.
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Second Card Section -->
                                    <div class="mb-2">
                                        <div class="rounded-corner-card">
                                            <div class="card">
                                                <div class="card-header">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <h5 style="display: flex; align-items: center;">
                                                            <i class="feather icon-map-pin" style="font-size: 20px; margin-right: 8px;"></i>
                                                            Address
                                                          </h5>      
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="country">Country</label>
                                                                <ng-select [items]="countries" bindLabel="countryName"
                                                                    bindValue="countryId" formControlName="country"
                                                                    placeholder="Select country"
                                                                    (change)="onCountryChange($event)">
                                                                </ng-select>
                                                                <span
                                                                    *ngIf="leadForm.get('country').invalid && (leadForm.get('country').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please
                                                                        Select
                                                                        Country</small>
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="state">State</label>
                                                                <ng-select [items]="states" bindLabel="stateName"
                                                                    bindValue="stateId" formControlName="state"
                                                                    placeholder="Select state">
                                                                </ng-select>
                                                                <span
                                                                    *ngIf="leadForm.get('state').invalid && (leadForm.get('state').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please
                                                                        Select
                                                                        State</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="city">City</label>
                                                                <input type="text" id="city" formControlName="city"
                                                                    class="form-control" placeholder="City" />
                                                                <span
                                                                    *ngIf="leadForm.get('city').invalid && (leadForm.get('city').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please
                                                                        Enter
                                                                        City</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="zipcode">Zip
                                                                    Code</label>
                                                                <input type="text" id="zipcode"
                                                                    formControlName="zipcode" class="form-control"
                                                                    placeholder="Zip Code" />
                                                                <span
                                                                    *ngIf="leadForm.get('zipcode').invalid && (leadForm.get('zipcode').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Enter
                                                                        Zipcode</small>
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="street">Street</label>
                                                                <input type="text" id="street" formControlName="street"
                                                                    class="form-control" placeholder="Street" />
                                                                <span
                                                                    *ngIf="leadForm.get('street').invalid && (leadForm.get('street').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please
                                                                        Enter
                                                                        Street</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <!-- First Card Section -->
                                    <div class="mb-2">
                                        <div class="rounded-corner-card">
                                            <div class="card">
                                                <div class="card-header">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <h5 style="display: flex; align-items: center;">
                                                            <i class="feather icon-list" style="font-size: 20px; margin-right: 8px;"></i>
                                                            Products
                                                          </h5>    
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="serviceType">Service Type</label>
                                                                <ng-select [items]="serviceTypes" bindLabel="name"
                                                                    bindValue="value" placeholder="Select Service Type"
                                                                    formControlName="serviceType"></ng-select>
                                                                <span
                                                                    *ngIf="leadForm.get('serviceType').invalid && leadForm.get('serviceType').touched"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Select
                                                                        serviceType</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="monthlyVolume">Monthly Volume</label>
                                                                <input id="monthlyVolume"
                                                                    formControlName="monthlyVolume"
                                                                    placeholder="Enter Monthly Volume" type="text"
                                                                    class="form-control">
                                                                <span
                                                                    *ngIf="leadForm.get('monthlyVolume').invalid && (leadForm.get('monthlyVolume').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Enter
                                                                        Monthly Volume</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="pol">POL</label>
                                                                <ng-select formControlName="pol"
                                                                    [items]="locationMasterData"
                                                                    bindValue="locationShortName"
                                                                    bindLabel="locationShortName"
                                                                    placeholder="Select POL"></ng-select>
                                                                <span
                                                                    *ngIf="leadForm.get('pol').invalid && (leadForm.get('pol').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Select
                                                                        POL</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="pod">POD</label>
                                                                <ng-select formControlName="pod"
                                                                    [items]="locationMasterData"
                                                                    bindValue="locationShortName"
                                                                    bindLabel="locationShortName"
                                                                    placeholder="Select POD"></ng-select>
                                                                <span
                                                                    *ngIf="leadForm.get('pod').invalid && (leadForm.get('pod').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Select
                                                                        POD</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Second Card Section -->
                                    <div class="mb-2">
                                        <div class="rounded-corner-card">
                                            <div class="card">
                                                <div class="card-header">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <h5 style="display: flex; align-items: center;">
                                                            <i class="feather icon-list" style="font-size: 20px; margin-right: 8px;"></i>
                                                            Additional Details
                                                          </h5>   
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="industry">Industry</label>
                                                                <ng-select [items]="industries" bindLabel="industryType"
                                                                    bindValue="industryId" placeholder="Select Industry"
                                                                    formControlName="industry"></ng-select>
                                                                <span
                                                                    *ngIf="leadForm.get('industry').invalid && (leadForm.get('industry').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Select
                                                                        Industry</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="annualRevenue">Annual Revenue
                                                                    (Turnover)</label>
                                                                <input id="annualRevenue"
                                                                    formControlName="annualRevenue"
                                                                    placeholder="Enter Annual Revenue" type="text"
                                                                    class="form-control">
                                                                <span
                                                                    *ngIf="leadForm.get('annualRevenue').invalid && leadForm.get('annualRevenue').touched"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Enter
                                                                        Annual Revenue</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="employeeCount">Employee Count</label>
                                                                <ng-select [items]="employeeCounts" bindLabel="name"
                                                                    bindValue="value"
                                                                    placeholder="Select Employee Count"
                                                                    formControlName="employeeCount"
                                                                    [dropdownPosition]="'bottom'" [clearable]="false"
                                                                    class="ng-select-custom"></ng-select>
                                                                <span
                                                                    *ngIf="leadForm.get('employeeCount').invalid && (leadForm.get('employeeCount').touched || submitted)"
                                                                    class="invalid-form">
                                                                    <small class="form-text text-danger">Please Select
                                                                        Employee Count</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="emailOptIn" formControlName="emailOptIn">
                                                                <label class="custom-control-label"
                                                                    for="emailOptIn">Email Opt-in</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    