<!-- Address -->
<div class="card shadow rounded-corners">
    <h5 class="timeline-header">
        <div class="panel-title d-flex align-items-center justify-content-between" style="padding: 20px;">
            <div class="d-flex align-items-center">
                <i class="feather icon-map-pin" style="font-size: 24px; margin-right: 8px;"></i>
                <h5 class="m-0">Address</h5>
            </div>
            <div class="d-flex align-items-center">
                <span class="icon-right cursor-pointer">

                    <i class="feather icon-plus-square" (click)="openEnquiryAddressesModal(enquiryId)"></i>
                    <!-- <i class="feather icon-plus-square" ></i> -->
                </span>
                
            </div>
        </div>
    </h5>
    <div class="card-body">
        <div class="mb-1 cursor-pointer" *ngFor="let detail of enquiryaddrDetailsList"
            >
            <div class="row">
                <div class="col-md-7">
                    <div class=""><b>{{ detail.addressTypeName }} Address</b></div>
                    <div class="pt-1">{{ detail.companyName}}, {{ detail.addressLine1 }},{{
                        detail.addressLine2 }}, {{ detail.city}}-{{ detail.zipcode}},{{ detail.countryName }},{{ detail.stateName}}</div>
                </div>
                <div class="col-md-5 text-right">
    
                    <div class="badge badge-light-success mr-1">{{ detail.addressTypeName||detail.addressTypeNick }}Address 
                    </div>
                   <span class="" (click)="copyAddress($event, detail)"><i class="feather icon-copy"></i></span>
                    <!-- <div class="icon-wrapper">
                                                                                                                                                          <i [data-feather]="data.key" [size]="24"></i>
                                                                                                                                                        </div> -->
                    <i [data-feather]="copy" [size]="24"></i>
                    <!-- <div *ngIf="showCopiedMessage" class="alert alert-success alert-dismissible fade show" role="alert">
                                                                                                                                                          Address copied to clipboard!
                                                                                                                                                          <button type="button" class="close" (click)="showCopiedMessage = false">
                                                                                                                                                            <span aria-hidden="true">&times;</span>
                                                                                                                                                          </button>
                                                                                                                                                        </div> -->
                </div>
    
            </div>
            <hr>
        </div>
    </div>
</div>