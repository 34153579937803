import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-customer-preview-header',
  templateUrl: './customer-preview-header.component.html',
  styleUrls: ['./customer-preview-header.component.scss']
})
export class CustomerPreviewHeaderComponent implements OnInit {
  

  constructor() { }

  ngOnInit(): void {
  }

}
