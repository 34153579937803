
     <div class="card-body shadow rounded-corners">
        <h5 class="timeline-header">
            <div class="panel-title">
                <h5 style="display: flex; align-items: center;">
                    <i class="feather icon-list" style="font-size: 24px; margin-right: 8px;"></i>
                    Basic Details
                </h5>
            </div>
        </h5>
        <!-- {{livRequestData | json}} -->
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-6">Requested Date</div>
                <b class="col-6">{{ livRequestData?.requestedDate | date: 'dd-MMM-yyyy' }}</b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Sales Person</div>
                <b class="col-6">{{ livRequestData?.salesPersonName }}</b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Customer Type</div>
                <!-- <b class="col-6">{{ livRequest.isNewCustomer}}</b> -->
                <b class="col-6">{{ livRequestData?.isNewCustomer ? 'Existing' : 'New' }}</b>
            </div>
            <hr>
            <div class="row mb-2">
                <div class="col-6">Branch</div>
                <b class="col-6">{{ livRequestData?.branch}}</b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Current Limit</div>
                <b class="col-6"></b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Requested Limit</div>
                <b class="col-6">₹ {{formatNumber(livRequestData?.creditLimit)}}</b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Current Payment Terms</div>
                <b class="col-6"></b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Payment Terms</div>
                <b class="col-6">{{livRequestData?.creditTerms}}</b>
            </div>
            <hr>
            <div class="row mb-2">
                <div class="col-6">Notes</div>
                <b class="col-6">{{livRequestData?.notes}}</b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Expected Turnover</div>
                <b class="col-6">{{formatNumber(livRequest?.expectedTurnover)}}</b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Expected Profit</div>
                <b class="col-6">{{formatNumber(livRequest?.expectedProfit)}}</b>
            </div>
            <div class="row mb-2">
                <div class="col-6">Profit Percentage</div>
                <b class="col-6">{{formatNumber(livRequest?.profitPercentage)}}</b>
            </div>
        </div>
    </div>