<!-- <p>credit-limit-req-list works!
    <a [routerLink]="['/liv-preview']" class="font-weight-bold">
      Go to Liv Preview
    </a>
  </p>
   -->

   <div class="content-body">
    <!-- Customer List -->
     <div class="f-listmargin">
      <section class="customer-list-wrapper">
        <div class="card">
          <!-- Customer List Header -->
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <!-- <div class="d-flex align-items-center">
                  <h1 class="d-flex align-items-center mr-1 ">Credit Limit Requests</h1>
                  <span class="badge badge-warning mb-1 " *ngIf="awaitingApprovedCount">{{awaitingApprovedCount}} Pending</span>
                 
                </div>  -->
                <div class="d-flex align-items-center">
                  <h1 class="mr-2 mb-0">Credit Limit Requests</h1>
                  <span class="badge badge-warning" *ngIf="awaitingApprovedCount">{{awaitingApprovedCount}} Pending</span>
                  <!-- <span *ngFor="let count of awaitingApprovedCounts; let i = index">
                    <span class="badge badge-warning" *ngIf="count">Page {{ i + 1 }}: {{ count }} Pending</span>
                  </span> -->
                </div>
                
              </div>
             
            </div>
            <div
              class="
                col-md-6 col-lg-4 col-12
                d-flex
                justify-content-start justify-content-md-end
                align-items-center
                offset-lg-2
              "
            >
             <!-- <div class="align-items-center"><div *ngIf="isDelegate" class="badge badge-light-secondary justify-content-start justify-content-md-start">{{message}}</div></div> -->
             <div class="ml-5">&nbsp;&nbsp;&nbsp;</div>
              <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
               
                <!-- <div class="col-10 col-md-6 mt-2 ml-1 ml-md-0 mt-md-0"> -->
                  <!-- <button class="btn btn-primary ml-2" routerLink="/customer/create" rippleEffect><span [data-feather]="'plus'" [class]="'mr-50'"></span>New</button> -->
                   
                <!-- </div> -->
                <div class="col-10 col-md-12 mt-2 ml-1 ml-md-0 mt-md-0 d-flex justify-content-start justify-content-md-end  " style="height: 40px;">
                  <div>
                    <!-- <label class="d-flex align-items-center">Status
                      <ng-select 
                        [items]="statusOptions" 
                        bindLabel="label" 
                        bindValue="value"
                        [(ngModel)]="selectedStatus" 
                        (change)="filterByStatus()"
                        class="ml-25" 
                        placeholder="All Statuses" 
                        style="width: 150px;">
                      </ng-select>
                    </label> -->
                    <input
                    [(ngModel)]="searchValue"
                    name="searchValue"
                    type="search"
                    class="form-control"
                    placeholder="Search by Status..."
                    (keyup)="filterUpdate($event)"
                    (search)="filterUpdate($event)"
                  />

                    <!-- <label class="d-flex align-items-center ml-1">Search
                      <input type="search" placeholder="Search" class="form-control ml-25" (keyup)="filterUpdate($event)" />
                    </label> -->
                  </div>
                  <!-- <button class="btn btn-primary mr-2"  rippleEffect style="height: 40px;">
                   <span class="mr-1"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> </span> Edit</button> -->
                  <!-- <div class="btn-group" *ngIf="!isDelegate"> -->
                  <div class="btn-group" *ngIf="isDelegate === false">
                    <!-- <div>

                      <select
                      (change)="filterRows($event.target.value)"
                      class="form-control"
                      style="width: 150px; margin-right: 10px;"
                    >
                      <option value="">All Statuses</option>
                      <option value="Approved">Approved</option>
                      <option value="AwaitingApproval">Awaiting Approval</option>
                      <option value="Rejected">Rejected</option>

                    </select>
                      
                    </div> -->
                    
                    <div>
                      <button
                        class="btn btn-primary mr-1 ml-1"
                        type="button"
                        id="dropdownMenuButton"
                        rippleEffect
                        (click)="openCreditLimitReqModal()"
                      >
                        New
                      </button>
                      <!-- <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                        <a ngbDropdownItem href="javascript:void(0)" (click)="openCreditLimitReqModal()">New</a>
                        
                      </div> -->
                      <!-- <span aria-hidden="true" class="close-big cursor-pointer" routerLink="/customerList">×</span> -->
                      <!-- <div aria-hidden="true" class="close-big cursor-pointer" >×</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/ Customer List Header -->
  
          <!-- Customer List Table -->
          <!-- <ngx-datatable
            [rows]="rows"
            [rowHeight]="50"
            class="bootstrap core-bootstrap"
            [limit]="selectedOption"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            [scrollbarH]="true"
          > -->
          <ngx-datatable
          class="bootstrap core-bootstrap"
        [rows]="livRequests"
        [rowHeight]="getRowHeight"
        [count]="totalRecords"
        [offset]="pageNumber - 1"
        [limit]="pageSize"
        [loadingIndicator]="loading"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        (page)='onPage($event)'>
            <!-- <ngx-datatable-column name="ID" prop="companyId" [width]="50">
              <ng-template let-companyId="value" ngx-datatable-cell-template>
                <a routerLink="/customer/edit/{{companyId}}" class="font-weight-bold">#{{ companyId }}</a>
              </ng-template>
            </ngx-datatable-column>
            -->
            
            <ngx-datatable-column name="Company Name" prop="customerName" [width]="240" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-customerName="value" let-row="row">
                <div class="font-weight-bold wrap-text">
                  <a [routerLink]="['/liv-preview', row['livRequestId']]" >
                    {{ customerName }}
                  </a>
                </div>
              </ng-template>
            </ngx-datatable-column>
            
            <!-- <ngx-datatable-column name="Sales Person" prop="salesPersonID" [width]="100">
              <ng-template let-salesPersonID="value" ngx-datatable-cell-template>
                {{ salesPersonID }}
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column name="Sales Person" prop="salesPersonID" [width]="150">
              <ng-template let-salesPersonID="value" ngx-datatable-cell-template>
                {{ salesPerson.get(salesPersonID) || 'Unknown' }}
              </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column name="Branch" prop="branchName" [width]="80">
              <ng-template let-branchName="value" ngx-datatable-cell-template>
                {{ branchName }}
              </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-column name="Limit" prop="creditLimit" [width]="80">
              <ng-template let-creditLimit="value" ngx-datatable-cell-template>
                {{ creditLimit }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Term" prop="creditTerms" [width]="80">
              <ng-template let-creditTerms="value" ngx-datatable-cell-template>
                {{ creditTerms }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Requested Date" prop="requestedDate" [width]="100">
              <ng-template let-requestedDate="value" ngx-datatable-cell-template>
                {{ requestedDate | date: 'dd / MM / yyyy HH:mm'  }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="120">
              <ng-template  let-row="row" ngx-datatable-cell-template>
                <span
                [ngClass]="{
                  'badge-custom': true,
                  'badge-success badge-light-success': row.status === 'Approved',
                  'badge-danger badge-light-danger': row.status === 'Rejected',
                  'badge-warning badge-light-warning': row.status === 'Canceled',
                  'badge-info badge-light-info': row.status === 'Awaiting Approval'
                }"
                >
                  {{ row.status }}
                </span>
              </ng-template>
            </ngx-datatable-column>
  
            <!--  -->
          </ngx-datatable>
          <!-- Pagination Controls -->
         <!-- Pagination Controls -->
        <ul class="pagination justify-content-end pr-1">
          <li class="page-item" [class.disabled]="pageNumber === 1">
            <a class="page-link" (click)="goToPreviousPage()">Previous</a>
          </li>

          <!-- Display page numbers with ellipsis -->
          <li *ngIf="pages[0] > 1" class="page-item">
            <a class="page-link" (click)="goToPage(1)">1</a>
          </li>
          <li *ngIf="pages[0] > 2" class="page-item disabled">
            <span class="page-link">...</span>
          </li>

          <li *ngFor="let page of pages" class="page-item" [class.active]="page === pageNumber">
            <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
          </li>

          <li *ngIf="pages[pages.length - 1] < totalPages - 1" class="page-item disabled">
            <span class="page-link">...</span>
          </li>
          <li *ngIf="pages[pages.length - 1] < totalPages" class="page-item">
            <a class="page-link" (click)="goToPage(totalPages)">{{ totalPages }}</a>
          </li>

          <li class="page-item" [class.disabled]="pageNumber === totalPages">
            <a class="page-link" (click)="goToNextPage()">Next</a>
          </li>
        </ul>
          <!--/ Customer List Table -->
        </div>
      </section>
      <!--/ Customer List -->
     </div>
    
  </div>



