<!-- Accordion Basic start -->
<div class="rounded-corner-accordian">
    <section id="accordion ">
      <div class="row">
        <div class="col-sm-12">
          <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
            <!-- <core-card-snippet [snippetCode]="_snippetCodeAccordion"> -->
    
            <div class="card-body collapse-icon p-0">
    
              <div class="collapse-default">
                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="panelBorderBottom1">
                  <ngb-panel id="panelBorderBottom1">
                    <ng-template ngbPanelTitle>
                      <div class="panel-title">
                        <h5 style="display: flex; align-items: center;">
                          <i class="feather icon-map-pin" style="font-size: 20px; margin-right: 8px;"></i>
                          Address
                        </h5>                      
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <div class="col-md-8">
                          <div class=""><b>Billing Address</b></div>
                          <div class="pt-1">{{lead}}
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          
                          <div class="badge badge-light-success mr-1">Billing</div>
                          <span class="pointer-cursor" (click)="copyToClipboard(billingAddress)" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></span>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
    
                </ngb-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
