
 <div class="row align-items-center m-1">
  <div class="col-lg-5 col-md-10 col-10 d-flex align-items-center mb-2 mb-md-0">
    <h2 class="mb-0 text-truncate flex-shrink-1">
      <b>{{leadDetails?.companyName}}</b>
    </h2>
    <div class="ml-2">
      <span [ngClass]="{
        'badge-custom': true,
        'badge-success badge-light-success': leadStatusName === 'Contacted',
        'badge-warning badge-light-warning': leadStatusName === 'Not contacted',
        'badge-danger badge-light-danger': leadStatusName === 'Lost',
        'badge-secondary badge-light-secondary': leadStatusName === 'Junk',
        'badge-info badge-light-info': leadStatusName === 'Not Qualified'
      }">
        {{leadStatusName}}
      </span>
    </div>
  </div>

  <div class="col-lg-5 col-md-10 col-10 d-flex justify-content-end align-items-center flex-wrap text-right">
    <button class="btn btn-primary mx-1 flex-shrink-0" [routerLink]="['/lead/create', leadDetails?.leadId]" rippleEffect style="height: 38px;">
      Edit
    </button>

    <button class="btn btn-success mx-1 flex-shrink-0" rippleEffect style="height: 38px;">
      Convert
    </button>

    <div ngbDropdown class="mx-1 flex-shrink-0">
      <button ngbDropdownToggle class="btn btn-primary w-100" type="button" id="dropdownMenuButton" rippleEffect style="height: 38px; width: 94px;">
        New
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
        <a ngbDropdownItem href="javascript:void(0)" (click)="openCallLogModal()">Call Log</a>
        <a ngbDropdownItem href="javascript:void(0)" (click)="openTaskDetailModal()">Task Details</a>
        <a ngbDropdownItem href="javascript:void(0)" (click)="openMeetingInformationModal()">Meeting Information</a>
      </div>
    </div>

    <span class="close-big mx-1 flex-shrink-0 mr-3" routerLink="/leadsList">×</span>
  </div>
</div>
