<div class="row"  style="margin-top: -30px;">
    <div class="col-md-7">
        <div class="overviewsection">
            <div class="mb-2">
                <app-lead-preview-overview-basic-detail [lead]="leadDetails"></app-lead-preview-overview-basic-detail>

            </div>
            <div class="mb-2">
                <app-lead-preview-overview-additionaldetails [lead]="leadDetails"> </app-lead-preview-overview-additionaldetails>
            </div>
            <div class="mb-2">
                <app-lead-preview-overview-address [lead]="billingAddress"></app-lead-preview-overview-address>
            </div>
            
        </div>
    </div>
    <div class="col-md-5">
        <div class="">
            <app-lead-preview-overview-products [lead]="leadDetails"></app-lead-preview-overview-products>
            <app-lead-preview-overview-timeline></app-lead-preview-overview-timeline>
        </div>

    </div>
</div>