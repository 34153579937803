<div class="modal-header">
  <h5 class="modal-title">
    <i class="feather icon-package" style="font-size: 16px; margin-right: 8px;"></i>
    <span>Add Required Equipment</span>
  </h5>
  <p class="btn-close cursor-pointer" aria-label="Close" (click)="activeModal.dismiss('Cross click')" aria-hidden="true"> × </p>
</div>

<!-- Modal Body -->
<form [formGroup]="requiredEquipmentForm" (ngSubmit)="addRequiredEquipment(requiredEquipmentForm)">
<div class="modal-body">
 <input type="hidden" formControlName="containerId"/>
 <input type="hidden" formControlName="enquiryId"/>
    <div class="row">
      <!-- Container Type input -->
      <div class="form-group col-6">
        <label for="containerType">Container Type</label>
        <ng-select [items]="containerTypes" 
                   bindLabel="name" 
                   bindValue="id" 
                   formControlName="containerTypesId"
                   (change)="onChangeContainer($event)"
                   [ngClass]="{
                    'ng-touched':
                    requiredEquipmentForm.get(
                        'containerTypesId'
                      ).touched,
                    error:
                    requiredEquipmentForm.get(
                        'containerTypesId'
                      ).invalid &&
                      requiredEquipmentForm.get(
                        'containerTypesId'
                      ).touched
                  }">
        </ng-select>
        <span *ngIf="
        requiredEquipmentForm.get(
          'containerTypesId'
        ).invalid &&
        requiredEquipmentForm.get(
          'containerTypesId'
        ).touched
      " class="invalid-form">
          <small class="form-text text-danger">Please Select Container
            Type</small>
        </span>
        <input type="hidden" formControlName="containerType" />
      </div>
  
      <!-- Count input -->
      <div class="form-group col-6">
        <label for="containerCount">Count</label>
          <input type="number" class="form-control" formControlName="containerCount"  
          [ngClass]="{
            'ng-touched':
            requiredEquipmentForm.get(
                'containerCount'
              ).touched,
            error:
            requiredEquipmentForm.get(
                'containerCount'
              ).invalid &&
              requiredEquipmentForm.get(
                'containerCount'
              ).touched
          }">
          <span *ngIf="
          requiredEquipmentForm.get(
            'containerCount'
          ).invalid &&
          requiredEquipmentForm.get(
            'containerCount'
          ).touched
        " class="invalid-form">
            <small class="form-text text-danger">Please Select Container
              Count</small>
          </span>
      </div>
    </div>
  </div>
  
  <!-- Modal Footer -->
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
    <button type="submit" class="btn btn-success ml-2" >Add</button>
  </div>
  <input type="hidden" formControlName="modifiedBy" value="userId"/>
  <input type="hidden" formControlName="createdBy" value="userId"/>
  <input type="hidden" formControlName="deletedBy" value="userId"/>

</form>
