<section class="customer-list-wrapper" >
    <div class="row">
        <div class="fixheader">
            <div class="row align-items-center liv-margin">
                <div class="col-lg-5 col-md-10 col-xs-10 col-10 d-flex align-items-center mb-2 mb-md-0">
                    <!-- <h2 class="mb-0 text-truncate flex-shrink-1"> -->
                    <h2 class="mb-0 flex-shrink-1">

                        <!-- <b>Suzuki Ltd</b> -->
                         <b>{{livRequest.customerName}}</b>
                    </h2>
                    
                        <!-- <span></span> -->
                        <span class="ml-1 badge badge-warning md-font">{{livRequest.status}}</span> 
                        <!-- isSalesPersonOrCreatedBy={{isSalesPersonOrCreatedBy}}<br>
                        isSaleOrCreatedByApprover={{isSaleOrCreatedByApprover}}<br>
                        levelStatusFlag={{levelStatusFlag}}<br>
                        StatusFlag={{StatusFlag}}<br>
                        finalStatus={{finalStatus}} -->

                        <!-- ApprovalLevelStatus: {{ApprovalLevelStatus}} ||  levelStatusFlag:  {{levelStatusFlag}} ||  showApprovalbtn:  {{showApprovalbtn}} ||
                        approvalTasks= {{approvalTasks}} || isSalesPersonOrCreatedBy={{isSalesPersonOrCreatedBy}} || isSaleOrCreatedByApprover:{{isSaleOrCreatedByApprover}} -->
                        <!-- <button *ngIf="isSalesPersonOrCreatedBy && levelStatusFlag">hello</button> -->

                        <!-- <div class="badge badge-warning">{{approvalTasks[0].levelStatus}}</div> -->
                    

                </div>


        

                    <!--      
                    <div class="col-lg-5 col-md-10 col-10 d-flex justify-content-end align-items-center flex-wrap text-right">

                        <button type="button" class="btn btn-outline-danger mr-1" (click)="openRejectModal()"  *ngIf="!isSaleOrCreatedByApprover && !isSalesPersonOrCreatedBy || showApprovalbtn"> Reject</button>

                        <button class="btn btn-success mr-1" type="submit" (click)="openApproveModal()" *ngIf="isDelegate">Approve</button>
                        
                        <button class="btn btn-success" type="submit" (click)="onSubmit()" *ngIf="showApprovalbtn && !isDelegate ">Approve</button>
                    
                        <button type="button" class="btn btn-outline-danger ml-1 mr-1" (click)="cancelLivRequest(LIVRequestId,userId)"  *ngIf="isSalesPersonOrCreatedBy || isSaleOrCreatedByApprover">Cancel</button>
                        <span class="close-big mx-1 flex-shrink-0 clickable" (click)="goBack()">×</span>
                    </div>
                </div> -->


                <!-- <div class="col-lg-5 col-md-10 col-10 d-flex justify-content-end align-items-center flex-wrap text-right">

                    <button type="button" class="btn btn-outline-danger mr-1" (click)="openRejectModal()" *ngIf="showApprovalbtn || !ApprovalLevelStatus"> Reject</button>

                    <button class="btn btn-success mr-1" type="submit" (click)="openApproveModal(LIVRequestId)" *ngIf="isDelegate && !ApprovalLevelStatus">delegate Approve</button>
                    
                    <button class="btn btn-success" type="submit" (click)="onSubmit()" *ngIf="showApprovalbtn && !isDelegate || !ApprovalLevelStatus">Approve</button>
                
                    <button type="button" class="btn btn-outline-danger ml-1 mr-1" (click)="cancelLivRequest(LIVRequestId,userId)"  *ngIf="isSalesPersonOrCreatedBy || isSaleOrCreatedByApprover &&  !ApprovalLevelStatus">Cancel</button>
                    <span class="close-big mx-1 flex-shrink-0 clickable" (click)="goBack()">×</span>
                </div> -->

            <div class="col-lg-6 col-md-10 d-flex justify-content-end align-items-center flex-wrap text-right mar-left" >
              <!-- <button type="button" class="btn btn-outline-danger mr-1" (click)="openRejectModal()" > <span [data-feather]="'circle-minus'" [class]="'mr-25'"></span> Reject</button> -->
                <div *ngIf="!loading">
                        <button type="button" class="btn btn-outline-danger mr-1 md-font" (click)="openRejectModal()" *ngIf="!isSalesPersonOrCreatedBy && !levelStatusFlag  && !isSubmitted && !levelStatusFlag  && livRequest.status !== 'Canceled'"><i class="feather icon-minus-circle" style="font-size: 16px; margin-right: 8px;"></i> Reject</button>

                        <button class="btn btn-success mr-1 md-font" type="submit" (click)="openApproveModal(LIVRequestId)" *ngIf="isDelegate && !levelStatusFlag  && !isSubmitted && !levelStatusFlag  && livRequest.status !== 'Canceled'"><i class="feather icon-check" style="font-size: 16px; margin-right: 8px;"></i> Approve</button>
                        
                        <button class="btn btn-success md-font" type="submit" (click)="onSubmit()" *ngIf="!isSalesPersonOrCreatedBy && !isDelegate && !levelStatusFlag  && !isSubmitted  && livRequest.status !== 'Canceled'"><i class="feather icon-check" style="font-size: 16px; margin-right: 8px;"></i>Approve</button>
                    
                        <!-- <button type="button" class="btn btn-danger ml-1 mr-1" (click)="cancelLivRequest(LIVRequestId,userId)"  *ngIf="(isSalesPersonOrCreatedBy || isSaleOrCreatedByApprover) && (livRequest.status=='Awaiting Approval' || !levelStatusFlag) "><i class="feather icon-minus-circle" style="font-size: 16px; margin-right: 8px;"></i>  Cancel Request</button> -->
                        <button type="button" class="btn btn-danger ml-1 mr-1 md-font" (click)="cancelLivRequest(LIVRequestId,userId)"  *ngIf="(isSalesPersonOrCreatedBy || isSaleOrCreatedByApprover) && 
                        (livRequest.status === 'Awaiting Approval' || !levelStatusFlag) && 
                        livRequest.status !== 'Approved' && 
                        livRequest.status !== 'Canceled' && 
                        livRequest.status !== 'Rejected'"><i class="feather icon-minus-circle" style="font-size: 16px; margin-right: 8px;"></i>  Cancel Request</button>
                </div>
                <span class="close-big mx-1 flex-shrink-0 clickable mr-2" (click)="goBack()">×</span>
            </div>
            <span class="ml-1 badge badge-secondary">{{message}}</span>

        </div>

            <div class="pl-1 f-pills">
                <div class="overview md-font">
                    <ul ngbNav #nav="ngbNav" class="nav nav-pills p-0">
                        <li ngbNavItem>
                            <a ngbNavLink (click)="setComponent('overview')"
                                [class.active]="currentComponent === 'overview'">Overview</a>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink (click)="setComponent('timeline')"
                                [class.active]="currentComponent === 'timeline'">Timelines</a>
                        </li>
                       
                        <li ngbNavItem>
                            <a ngbNavLink (click)="setComponent('documents')"
                                [class.active]="currentComponent === 'documents'">Documents</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>

        <div class="mt-0 f-preview-overview-content md-font-component p-0" >
            <app-liv-preview-overview-section *ngIf="currentComponent === 'overview'"  @fade></app-liv-preview-overview-section>
          
            <app-liv-preview-timeline-section  [taskTimeLineData]="taskTimeLineData"
            *ngIf="currentComponent === 'timeline'" 
            @fade
            ></app-liv-preview-timeline-section>

            <app-liv-document-section *ngIf="currentComponent === 'documents'"  @fade></app-liv-document-section>
        </div>
     
    </div>
</section>
