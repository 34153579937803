<div class="card-body mt-1 shadow rounded-corners">
  <h5 class="timeline-header">
    <div class="panel-title">
      <h5 style="display: flex; align-items: center;">
        <i class="feather icon-list" style="font-size: 20px; margin-right: 8px;"></i>
        TimeLine
      </h5>      
    </div>
  </h5>


  <ul class="timeline mt-2">
    <li class="timeline-item">
      <span class="timeline-point timeline-point-warning timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h6>Client Meeting - Edwin Xavier</h6>
          <span class="timeline-event-time">Today</span>
        </div>
        <p>Meeting with Rakesh Kumar @ 10:15am.</p>
        <div class="media align-items-center">
          <div class="avatar mr-1">
            <img src="assets/images/avatars/12-small.png" alt="Avatar" height="32" width="32" />
          </div>
          <div class="media-body">
            <h6 class="mb-0">Rakesh Kumar (Client)</h6>
            <span>Logistics Manager at Global Motors</span>
          </div>
        </div>
      </div>
    </li>
    <li class="timeline-item">
      <span class="timeline-point timeline-point-info timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h6>Phone Call</h6>
          <span class="timeline-event-time">2 Days Ago</span>
        </div>
        <p>Called and Discussed prospect</p>
      </div>
    </li>
    <li class="timeline-item">
      <span class="timeline-point timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h6>Lead set to Contacted</h6>
          <span class="timeline-event-time">6 Days Ago</span>
        </div>
        <p>Contacted by Phone</p>
        <div class="media align-items-center">
          <img class="mr-1" src="assets/images/icons/xls.png" alt="xls" height="23" />
          <div class="media-body">Air Shipments</div>
          <img class="mr-1" src="assets/images/icons/doc.png" alt="doc" height="23" />
          <div class="media-body">Ocean Shipments</div>
        </div>
      </div>
    <li class="timeline-item">
      <span class="timeline-point timeline-point-secondary timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h6>Lead Created</h6>
          <span class="timeline-event-time">10 Days Ago</span>
        </div>
        <p>Lead created by Import</p>
      </div>
    </li>
  </ul>
</div>