
<div class="row">
    <div class="col-md-7">
        <div class="overviewsection">
            <div class="mb-2">
                <app-enquiry-preview-basic-detail></app-enquiry-preview-basic-detail>
            </div>
            <div class="mb-2">
                <app-enquiry-preview-enquiry></app-enquiry-preview-enquiry>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="mb-2">
            <app-enquiry-preview-address></app-enquiry-preview-address>
        </div>
        <div class="">
            <app-enquiry-preview-timeline></app-enquiry-preview-timeline>
       </div>
    </div>
</div>