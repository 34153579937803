<!-- The modal content will be rendered by NgbModal -->
<div class="modal-header">
  <h5 class="modal-title">
    <i class="feather icon-package" style="font-size: 16px; margin-right: 8px;"></i>
    <span>Add Package Details</span>
  </h5>
  <p class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" aria-hidden="true"
     class="cursor-pointer"> × </p>
</div>

<form [formGroup]="newpakagesForm" (ngSubmit)="addPackageDetails(newpakagesForm)">
  
  <input type="hidden" formControlName="enquiryPackageId"/>
  <input type="hidden" formControlName="enquiryId"/>
 
  <!-- Modal Body -->
  <div class="modal-body ">
    
    <div class="row">
      <!-- Package Type input -->
      <div class="form-group col-6">
        <label for="packageType">Package Type</label>
        <ng-select [items]="packageTypes" [selectOnTab]="true" [searchable]="true" 
                   bindLabel="name" bindValue="id" 
                   formControlName="packageTypeId"
                   (change)="onChangePakageType($event)">
        </ng-select>
        <input type="hidden" formControlName="packageType"/>
      </div>
      
      <!-- Total Gross Weight input -->
      <div class="form-group col-6">
        <label for="grossWeight">Total Gross Weight</label>
        <input type="number" class="form-control" id="grossWeight" formControlName="totalGrossWeight"  disabled/>
      </div>
    </div>
    
    <div class="form-group">
      <label for="packageDetails">Package Details</label>
      <div class="input-group">
        <input type="number" class="form-control" placeholder="Package Count" formControlName="packageCount" 
        [ngClass]="{
          'ng-touched':
          newpakagesForm.get(
              'packageCount'
            ).touched,
          error:
          newpakagesForm.get(
              'packageCount'
            ).invalid &&
            newpakagesForm.get(
              'packageCount'
            ).touched
        }"/>
        <!-- <span *ngIf="
        newpakagesForm.get(
          'packageCount'
        ).invalid &&
        newpakagesForm.get(
          'packageCount'
        ).touched
      " class="invalid-form">
          <small class="form-text text-danger">Please Select Pakage
            Count</small>
        </span> -->

        <input type="number" class="form-control" placeholder="L (mm)" formControlName="lengthMm" (change)="calculateTotalGrossWeight(newpakagesForm)" [ngClass]="{
          'ng-touched':
          newpakagesForm.get(
              'lengthMm'
            ).touched,
          error:
          newpakagesForm.get(
              'lengthMm'
            ).invalid &&
            newpakagesForm.get(
              'lengthMm'
            ).touched
        }"/>
        <!-- <span *ngIf="
        newpakagesForm.get(
          'lengthMm'
        ).invalid &&
        newpakagesForm.get(
          'lengthMm'
        ).touched
      " class="invalid-form">
          <small class="form-text text-danger">Please Select Pakage
            Count</small>
        </span> -->
        <input type="number" class="form-control" placeholder="W (mm)" formControlName="weightMm" (change)="calculateTotalGrossWeight(newpakagesForm)" [ngClass]="{
          'ng-touched':
          newpakagesForm.get(
              'weightMm'
            ).touched,
          error:
          newpakagesForm.get(
              'weightMm'
            ).invalid &&
            newpakagesForm.get(
              'lengthMm'
            ).touched
        }"/>
        <!-- <span *ngIf="
        newpakagesForm.get(
          'weightMm'
        ).invalid &&
        newpakagesForm.get(
          'weightMm'
        ).touched
      " class="invalid-form">
          <small class="form-text text-danger">Please Select Pakage
            Count</small>
        </span> -->
        <input type="number" class="form-control" placeholder="H (mm)" formControlName="heightMm" (change)="calculateTotalGrossWeight(newpakagesForm)"  [ngClass]="{
          'ng-touched':
          newpakagesForm.get(
              'heightMm'
            ).touched,
          error:
          newpakagesForm.get(
              'heightMm'
            ).invalid &&
            newpakagesForm.get(
              'heightMm'
            ).touched
        }"/>
        <!-- <span *ngIf="
        newpakagesForm.get(
          'heightMm'
        ).invalid &&
        newpakagesForm.get(
          'heightMm'
        ).touched
      " class="invalid-form">
          <small class="form-text text-danger">Please Select Pakage
            Count</small>
        </span> -->
        <input type="number" class="form-control" placeholder="Net Wt" formControlName="netWeight" [ngClass]="{
          'ng-touched':
          newpakagesForm.get(
              'netWeight'
            ).touched,
          error:
          newpakagesForm.get(
              'netWeight'
            ).invalid &&
            newpakagesForm.get(
              'netWeight'
            ).touched
        }"/>
        <!-- <span *ngIf="
        newpakagesForm.get(
          'netWeight'
        ).invalid &&
        newpakagesForm.get(
          'netWeight'
        ).touched
      " class="invalid-form">
          <small class="form-text text-danger">Please Select Pakage
            Count</small>
        </span> -->
        <input type="number" class="form-control" placeholder="Gross Wt" formControlName="grossWeight" disabled/>
        <input type="number" class="form-control" placeholder="Vol. Wt" formControlName="volumeWeight" disabled />
        <input type="number" class="form-control" placeholder="CBM" formControlName="cbm" [ngClass]="{
          'ng-touched':
          newpakagesForm.get(
              'cbm'
            ).touched,
          error:
          newpakagesForm.get(
              'cbm'
            ).invalid &&
            newpakagesForm.get(
              'cbm'
            ).touched
        }"/>
        <!-- <span *ngIf="
        newpakagesForm.get(
          'cbm'
        ).invalid &&
        newpakagesForm.get(
          'cbm'
        ).touched
      " class="invalid-form">
          <small class="form-text text-danger">Please Select Pakage
            Count</small>
        </span> -->
      </div>
    </div>
  </div>
  
  <!-- Modal Footer -->
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
    <button type="submit" class="btn btn-success ml-2" >Add</button>

    <input type="hidden" formControlName="modifiedBy" value="userId"/>
    <input type="hidden" formControlName="createdBy" value="userId"/>
    <input type="hidden" formControlName="deletedBy" value="userId"/>
  </div>
</form>