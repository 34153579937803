<div class="scroll-container">
    <div class="d-flex mt-1">
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-left">
                    <div class=" pb-0">
                        <div class="avatar-content">
                            <!-- <i data-feather="credit-card" class="avatar-icon"></i> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                        </div>
                        <h4 class="mt-1"><b>Total Sales</b></h4>
                        <h6 class="mt-0">Last Week</h6>
                    </div>
                    <div class=" mt-0">
                        <p class="value">24.67k</p>
                        <span class="percentage">+25.2%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-left">
                    <div class=" pb-0">
                        <div class="avatar-content-red">
                            <!-- <i data-feather="credit-card" class="avatar-icon"></i> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                        </div>
                        <h4 class="mt-1"><b>Total Sales</b></h4>
                        <h6 class="mt-0">Last Week</h6>
                    </div>
                    <div class=" mt-0">
                        <p class="value">24.67k</p>
                        <span class="percentage-red">+25.2%</span>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-left">
                    <div class=" pb-0">
                        <div class="avatar-content-red">
                            <!-- <i data-feather="credit-card" class="avatar-icon"></i> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                        </div>
                        <h4 class="mt-1"><b>Total Sales</b></h4>
                        <h6 class="mt-0">Last Week</h6>
                    </div>
                    <div class=" mt-0">
                        <p class="value">24.67k</p>
                        <span class="percentage-red">+25.2%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-left">
                    <div class=" pb-0">
                        <div class="avatar-content">
                            <!-- <i data-feather="credit-card" class="avatar-icon"></i> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                        </div>
                        <h4 class="mt-1"><b>Total Sales</b></h4>
                        <h6 class="mt-0">Last Week</h6>
                    </div>
                    <div class=" mt-0">
                        <p class="value">24.67k</p>
                        <span class="percentage">+25.2%</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Repeat for other columns as needed -->
    </div>
</div>
