import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rejectmsgpage',
  templateUrl: './rejectmsgpage.component.html',
  styleUrls: ['./rejectmsgpage.component.scss']
})
export class RejectmsgpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
