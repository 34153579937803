<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section class="leads-list-wrapper">
        <div class="card">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <div class="d-flex align-items-center">
                  <h4 class="d-flex align-items-center">Leads</h4>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2">
              <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
                <div class="col-10 col-md-6 pr-0">
                  <label class="d-flex align-items-center mb-0 ml-1 ml-md-0">
                    <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control" placeholder="Search..." (keyup)="filterUpdate($event)" (search)="filterUpdate($event)" />
                  </label>
                </div>
                <div class="col-10 col-md-6 mt-2 ml-1 ml-md-0 mt-md-0">
                  <button class="btn btn-primary ml-2" routerLink="/lead/create" rippleEffect><span [data-feather]="'plus'" class="mr-50"></span>New</button>
                </div>
              </div>
            </div>
          </div>
          <ngx-datatable [rows]="rows"   [rowHeight]="40" class="bootstrap core-bootstrap" [limit]="selectedOption" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">
            <!-- <ngx-datatable-column name="ID" prop="leadId" [width]="50">
              <ng-template let-leadId="value" ngx-datatable-cell-template>
                <a routerLink="/apps/lead/preview/{{ leadId }}" class="font-weight-bold">#{{ leadId }}</a>
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column name="Customer" prop="companyName" [width]="210">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/lead/preview', row.leadId]" class="font-weight-bold">
                {{ row.companyName.length > 20 ? (row.companyName | slice:0:20) + '...' : row.companyName }}
              </a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Source" prop="leadSourceId" [width]="150">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <!-- {{ leadSourcesMap.get(leadSourceId) }} -->
                {{ row.leadSource }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Lead Owner" prop="leadOwnerId" [width]="150">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <!-- {{ salesPerson.get(leadOwnerId) }} -->
                  {{row.leadOwner}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="leadStatusId" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span [ngClass]="{
                  'badge-custom': true,
                  'badge-success badge-light-success': row.leadStatus === 'Contacted',
                  'badge-warning badge-light-warning': row.leadStatus === 'Not contacted',
                  'badge-danger badge-light-danger': row.leadStatus === 'Lost',
                  'badge-secondary badge-light-secondary': row.leadStatus === 'Junk',
                  'badge-info badge-light-info': row.leadStatus === 'Not Qualified'
                }">
                    {{row.leadStatus}}
                </span>
              </ng-template>
            </ngx-datatable-column>            
            <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center col-actions">
                  <a class="mr-1" href="javascript:void(0);" container="body" placement="top" ngbTooltip="Send Mail">
                    <i size="18" data-feather="send"></i>
                  </a>
                  <!-- <a class="mr-1" [routerLink]="['/lead/preview', row.leadId]" container="body" placement="top" ngbTooltip="Preview Lead">
                    <i size="18" data-feather="eye"></i>
                  </a> -->
                  <div ngbDropdown container="body">
                    <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow px-0" rippleEffect>
                      <i data-feather="more-vertical" size="18" class="cursor-pointer"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a [routerLink]="['/lead/create', row.leadId]" ngbDropdownItem>
                        <i data-feather="edit" class="mr-1"></i>Edit 
                      </a>
                      <a href="javascript:void(0)" ngbDropdownItem  (click)="deleteLead(row.leadId)">
                        <i data-feather="trash" class="mr-1"></i>Delete 
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </section>
    </div>
  </div>
  