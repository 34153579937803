<!-- Accordion Basic start -->
<div class="rounded-corner-accordian">
    <section id="accordion ">
        <div class="row">
            <div class="col-sm-12">
                <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
                    <!-- <core-card-snippet [snippetCode]="_snippetCodeAccordion"> -->

                    <div class="card-body collapse-icon p-0">

                        <div class="collapse-default">
                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="panelBorderBottom1">
                                <ngb-panel id="panelBorderBottom1">
                                    <ng-template ngbPanelTitle>
                                        <div class="panel-title">
                                            <h5 style="display: flex; align-items: center;">
                                                <i class="feather icon-list" style="font-size: 20px; margin-right: 8px;"></i>
                                                Products
                                              </h5>      
                                            <span class="icon-right">
                                                <i class="feather icon-eye" style="font-size: 20px; margin-right: 8px;"></i>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="row">
                                            <div class="col-md-4">Products</div>
                                            <div class="col-md-8"><b>{{lead?.shipmentType}}</b></div>
                                        </div>
                                        <div class="row pt-1">
                                            <div class="col-md-4">Ports</div>
                                            <div class="col-md-8"><b>{{lead?.pol}}, {{lead?.pod}}</b></div>
                                        </div>
                                        <div class="row pt-1">
                                            <div class="col-md-4">Monthly TEUs</div>
                                            <div class="col-md-8"><b>{{lead?.monthlyTeus}}</b></div>
                                        </div>
                                        <!-- <div class="row pt-1">
                                            <div class="col-md-4">Monthly CBM</div>
                                            <div class="col-md-8"><b>-</b></div>
                                        </div>
                                        <div class="row pt-1">
                                            <div class="col-md-4">Monthly KGs</div>
                                            <div class="col-md-8"><b>4000 kgs</b></div>
                                        </div> -->
                                    </ng-template>
                                </ngb-panel>

                            </ngb-accordion>
                        </div>
                    </div>
                    <!-- </core-card-snippet> -->
                </div>
            </div>
        </div>
    </section>
</div>
<!-- Accordion Basic end -->