<div class="card shadow rounded-corners mb-2">
  <h5 class="timeline-header">
    <div class="panel-title">
      <h5 style="display: flex; align-items: center; margin-top: 20px; margin-left: 20px;">
          <i class="feather icon-list" style="font-size: 24px; margin-right: 8px;"></i>
          Basic Details
        </h5>          
    </div>
  </h5>
 
  <div class="card-body">
      <div *ngIf="basicDetail[0]">
        <div class="row mb-2" >
            <div class="col-4">Sales Person</div>
            <b class="col-8">{{basicDetail[0].userDisplayName}}</b>
        </div>
        <div class="row mb-2">
            <div class="col-4">Customer Type</div>
            <b class="col-8">{{basicDetail[0].conpanyType}}</b>
        </div>
        <div class="row mb-2">
            <div class="col-4">Company Name</div>
            <b class="col-8">{{basicDetail[0].companyName}}</b>
        </div>
      </div>
      <hr>
      <div *ngIf="leadData && leadData.length > 0">
        <div class="row mb-2">
            <div class="col-4">Contact</div>
            <b class="col-8">{{ leadData[0].firstName }} ( {{ leadData[0].title }} )</b>
        </div> 
        <div class="row mb-2">
            <div class="col-4">Email</div>
            <b class="col-8">
              <i class="feather icon-copy" style="margin-right: 8px; cursor: pointer;" (click)="copyToClipboard('')"></i>{{ leadData[0].email }}
            </b>
        </div>
        <div class="row mb-2">
            <div class="col-4">Mobile</div>
            <b class="col-8">
              <i class="feather icon-copy" style="margin-right: 8px; cursor: pointer;" (click)="copyToClipboard('')"></i>{{ leadData[0].phone1 }} / {{ leadData[0].phone1 }} 
            </b>
        </div>
        <hr>
        <div class="row mb-2">
            <div class="col-4">Lead Status</div>
            <b class="col-8">{{basicDetail[0].leadStatusName}}</b>
        </div>
      </div>
      
    
        <div *ngIf="companyData && companyData.length > 0">
        <div class="row mb-2">
            <div class="col-4">Contact</div>
            <b class="col-8">{{ companyData[0].displayName }} ( {{ companyData[0].designation }}  {{ companyData[0].department }})</b>
        </div> 
        <div class="row mb-2">
            <div class="col-4">Email</div>
            <b class="col-8">
              <i class="feather icon-copy" style="margin-right: 8px; cursor: pointer;" (click)="copyToClipboard('')"></i>{{ companyData[0].email }}
            </b>
        </div>
         <div class="row mb-2">
            <div class="col-4">Mobile</div>
            <b class="col-8">
              <i class="feather icon-copy" style="margin-right: 8px; cursor: pointer;" (click)="copyToClipboard('')"></i>{{ companyData[0].mobile }}  
            </b>
        </div>
        <hr>
        <!--<div class="row mb-2">
            <div class="col-4">Lead Status</div>
            <b class="col-8">{{companyData[0].companyData}}</b>
        </div> -->
    </div>
    <div class="row mb-2">
      <div class="col-4">Notes</div>
      <b class="col-8">{{basicDetail[0].notes}}</b>
  </div>
</div>
