
                <!-- Customer List Header -->
                <div class="row">
                    <div class="col-md-5 col-12">
                      <div class="d-flex justify-content-between align-items-center m-1">
                        <div class="d-flex align-items-center">
                          <h2 class=""><b>Ring Plus Aqua Ltd</b></h2><div class="badge badge-success ml-1 mb-1">Active</div>
                          <!-- Add other controls if necessary -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                      col-md-7 col-lg-3 justify-content-start justify-content-md-end align-items-center offset-lg-2
                      "
                    >
                      <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100 mr-left">
                       
                        <div class="col-10 col-md-12 mt-2 ml-1 ml-md-0 mt-md-0 d-flex justify-content-start justify-content-md-end  ">
                          <button class="btn btn-primary mr-2" routerLink="/apps/invoice/add" rippleEffect>
                           <span class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg> </span> Edit</button>
                          <div class="btn-group">
                            <div ngbDropdown>
                              <button
                                ngbDropdownToggle
                                class="btn btn-primary mr-1"
                                type="button"
                                id="dropdownMenuButton"
                                rippleEffect
                              >
                                Primary
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                                <a ngbDropdownItem href="javascript:void(0)">Option 1</a>
                                <a ngbDropdownItem href="javascript:void(0)">Option 2</a>
                                <a ngbDropdownItem href="javascript:void(0)">Option 3</a>
                              </div>
                              <span aria-hidden="true" class="close-big cursor-pointer" routerLink="/customerList">×</span>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  <!--/ Customer List Header -->
            